module.exports = {
  baseUrl: process.env.BASE_URL || "https://0.0.0.0:4001",
  baseApi: "https://squad-100.supermercap.com/api",
  productServicesBase: "https://product-services.squad-100.supermercap.com",
  host: "0.0.0.0",
  port: 4000,
  path: "",
  urlWebOld: "https://squad-100.supermercap.com",
  leanplum: {
    devMode: false,
    appId: "app_OlcY2VVtVpCALzLYwsXLKUplg5DHceeITEtNjaVrcLg",
    appKey: "dev_G8l9lKgFUEQ91CsNC5MbrAlRuD1lYljLkDAVFZoSZz8"
  },
  sentryDNS: "https://24101f686af04c578a5c55b338c23aba@sentry.io/1398901",
  sentryAuthToken: "afaf50cba3d74634a2e7893e3e75418755c8caaf9dfb4485a5f76c5e1ffc8b86",
  sentryOrg: "merqueo-web-v2-dev",
  sentryTagRelease: 'm-web-v2-dev@3.18.7', deployVersion: '3.18.7', deployTime: '9/9/2024, 16:38:26', 
  sentryProject: "m-web-v2-dev",
  FBappID: "1627519407513186",
  TagmanagerID: "GTM-KT2VTV2",
  lang: "es-CO",
  codeCurrency: "COP",
  configFirebase: {
    apiKey: "AIzaSyAHtPa8gzKvFfOkpV0JVhwcgpEqTOoKUds",
    authDomain: "merqueo-dev.firebaseapp.com",
    databaseURL: "https://merqueo-dev.firebaseio.com",
    projectId: "merqueo-dev",
    storageBucket: "merqueo-dev.appspot.com",
    messagingSenderId: "231780925996",
    appId: "1:231780925996:web:f033b70d57c410278d0789"
  },
  DEFAULT_COUNTRIES: [
    {
      id: 1,
      attributes: {
        name: "Colombia",
        url_domain: "https://squad-100.supermercap.com",
        url_country_flag: "https://static.merqueo.com/images/i18n/Bandera_col.png"
      }
    },
    {
      id: 2,
      attributes: {
        name: "Mexico",
        url_domain: "https://mx.squad-100.supermercap.com",
        url_country_flag: "https://static.merqueo.com/images/i18n/Bandera_mex.png"
      }
    },
  ],
  googleMapsKey: "AIzaSyDBWc8csTon6ckrP4XFAvg2703TDHQwljU",
  testingNameEnable: true,
  trackApi: "https://product-services.squad-100.supermercap.com/track/v1",
  uriGenerateCodeWhatsapp: "https://product-services.squad-100.supermercap.com/otp-generate-whatsapp/v1",
  uriVerifiedCodeWhatsapp: "https://product-services.squad-100.supermercap.com/otp-verify/v1",
  apiZendesk: {
    url: "https://merqueo.zendesk.com/api/v2/requests.json",
    urlUploads: "https://merqueo.zendesk.com/api/v2/uploads.json",
    customFields: {
      userId: "360007406113",
      orderId: "360000624714",
      channel: "44232728",
    },
  },
  ENV_FLAG: "development",
  kustomerApiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMmEyZWFiMDFhNjY5MWU0OWQyZDc4OSIsInVzZXIiOiI2MjJhMmVhYTFhYmZkOTMyM2U3ZDhjMTEiLCJvcmciOiI2MWVhYmYyZmM3ZmIzMjgxOThiZjY4ZDQiLCJvcmdOYW1lIjoibWVycXVlby1zYW5kYm94IiwidXNlclR5cGUiOiJtYWNoaW5lIiwicG9kIjoicHJvZDEiLCJyb2xlcyI6WyJvcmcuYWRtaW4iLCJvcmcudXNlciJdLCJhdWQiOiJ1cm46Y29uc3VtZXIiLCJpc3MiOiJ1cm46YXBpIiwic3ViIjoiNjIyYTJlYWExYWJmZDkzMjNlN2Q4YzExIn0.4Un6UuHYAZbUgNi96CWqXFg7oOaljOLKJqTFcqCq8zY",
  schedulesCountry: {
    co: "6269bc13f0b0aa97c16979c3",
    mx: "626ab0fcf0b0aa0f4e6e7d48",
    br: "626ab136f0b0aa48406e7f60",
  },
  kustomerAssistantId: "6275410eec7a025c80a22436",
  gitBranch: process.env.GIT_BRANCH || "N/A",
  kustomerAssistantId: "6275410eec7a025c80a22436",
  kustomerSurveyId : "61eabf33b56f1640bbc17a6f",
  kustomerQuestionId : "61eabf33b56f161dd8c17a6e",
  urlPingDomSnippet: ""
};
